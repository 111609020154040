<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>{{ $tc('user') }}</span>
      <router-link v-if="['1', '2'].includes(setting.roleId)" class="card-header-button el-button el-button--primary el-button--mini" style="float: right;" tag="button" to="/users/create">
        <i class="fad fa-plus">
        </i>&nbsp;{{ $t('create') }}
      </router-link>
    </div>
    <div>
      Estados de la empresa :
      <el-tag type="info">
        Lectura
      </el-tag>
      <el-tag type="warning">
        Suspendida
      </el-tag>
      <el-tag type="danger">
        Cancelada
      </el-tag>
      <el-tag type="danger">
        PruebaFinalizada
      </el-tag>
    </div>
    <br>
    <el-tabs type="border-card">
      <el-tab-pane>
        <span slot="label">
          <i class="el-icon-search"></i> Buscar</span>
        <el-form :inline="true">
          <el-form-item>
            <select-company v-model="selectedCompany"></select-company>
          </el-form-item>
          <el-form-item>
            <select-service v-model="selectedService"></select-service>
          </el-form-item>
          <el-form-item>
            <el-input v-model="searchEmail" :placeholder="$t('email')"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button size="mini" type="primary" icon="el-icon-search" @click="find">
              Buscar
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <br>
    <el-table v-loading="loading" :data="users" style="width: 100%" stripe border>
      <el-table-column v-if="['1', '2'].includes(setting.roleId)" :label="$tc('action', 2)" :width="110" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button-group v-if="scope.row.roleId > 0" size="mini">
            <el-button v-if="+scope.row.companyKey > 0" size="mini" type="primary" icon="el-icon-edit" @click.prevent="onUpdate(scope.row)"></el-button>
            <el-button v-if="scope.row.userStatus !== 'eliminado'" size="mini" type="danger" icon="el-icon-delete" @click.prevent="remove(scope.row, scope.$index)"></el-button>
            </el-button-group>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="userStatus" label="Estado" sortable width="120">
        <template slot-scope="scope">
          <el-button size="mini" :type="scope.row.userStatus.toLowerCase()">
            {{ scope.row.userStatus.toUpperCase() }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="Rol" align="center" width="45">
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.roleName" :content="'Rol : ' + scope.row.roleName" placement="right">
            <span>
              <i v-if="scope.row.userIsAliaddo" class="fa fa-user-tie text-success"></i>
              <i v-else class="fa fa-user text-info"></i>
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column :label="$tc('user', 1)" prop="userName" sortable>
        <template slot-scope="scope">
          {{ scope.row.userName }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('email')" prop="userEmail"></el-table-column>
      <el-table-column :label="$tc('name', 1)" prop="userFullName" sortable></el-table-column>
      <el-table-column :label="$t('phone')" prop="userPhone"></el-table-column>
      <el-table-column :label="$tc('company', 1)" prop="companyName">
        <template slot-scope="scope">
          <div v-if="scope.row.companyServiceStatus && (scope.row.companyServiceStatus.toLowerCase() === 'lectura' || scope.row.companyServiceStatus.toLowerCase() === 'suspendida' || scope.row.companyServiceStatus.toLowerCase() === 'cancelada' || scope.row.companyServiceStatus.toLowerCase() === 'pruebaFinalizada')">
            <el-tag v-if="scope.row.companyServiceStatus.toLowerCase() === 'lectura'" type="info">
              {{ scope.row.companyName }}
            </el-tag>
            <el-tag v-if="scope.row.companyServiceStatus.toLowerCase() === 'suspendida'" type="warning">
              {{ scope.row.companyName }}
            </el-tag>
            <el-tag v-if="scope.row.companyServiceStatus.toLowerCase() === 'cancelada'" type="danger">
              {{ scope.row.companyName }}
            </el-tag>
            <el-tag v-if="scope.row.companyServiceStatus.toLowerCase() === 'pruebaFinalizada'" type="danger">
              {{ scope.row.companyName }}
            </el-tag>
          </div>
          <div v-else>
            {{ scope.row.companyName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('service')" prop="serviceName"></el-table-column>
      <el-table-column prop="userCreatedBy" label width="75px">
        <template slot-scope="scope">
          <el-tooltip :content="'Creado : ' + $options.filters.formatToDate(scope.row.userCreatedAt) + (scope.row.userCreatedBy ? ' por ' + scope.row.userCreatedBy : '')" effect="dark" placement="left">
            <el-button size="mini">
              <i class="fa fa-paw"></i>
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageNumber"
      :page-size="10"
      :page-sizes="[10, 20, 30, 40, 50]"
      :total="rowsCount"
      layout="total, sizes, prev, pager, next, jumper"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    >
    </el-pagination>
  </el-card>
</template>
<script>
import { mapGetters } from 'vuex'
import { i18n } from '../../i18n'
import router from '../../router'

export default {
  data() {
    return {
      users: [],
      selectedCompany: '',
      selectedService: '',
      searchEmail: '',
      loading: false,

      /* PAGINATE */
      pageCount: 0,
      pageSize: 10,
      pageNumber: 1,
      rowsCount: 0,
      skip: 0,
    }
  },
  computed: {
    ...mapGetters(['setting']),
    roleId() {
      return JSON.parse(localStorage.getItem('masters')).roleId
    },
  },
  mounted() { },
  methods: {
    find() {
      const _this = this
      _this.loading = true
      let company = ''
      let service = ''
      if (_this.selectedCompany) {
        company = _this.selectedCompany
      }

      if (_this.selectedService) {
        service = _this.selectedService
      }

      _this.$http.get(`users/findByFilter?searchCompany=${company}&searchService=${service}&searchEmail=${_this.searchEmail}&limit=${_this.pageSize}&offset=${_this.pageNumber - 1}`).then(
        response => {
          _this.loading = false
          _this.users = response.body.data
          _this.rowsCount = response.body.count
          _this.loading = false
          _this.pageCount = Math.ceil(_this.rowsCount / _this.pageSize)
          if (_this.pageCount > 0 && _this.pageCount < _this.pageNumber) {
            _this.pageNumber = 1

            if (_this.$refs.paginate) {
              _this.$refs.paginate.selected = 0
            }
          }
        },
        response => {
          _this.loading = false
        },
      )
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.find()
    },
    handleCurrentChange(val) {
      this.pageNumber = val
      this.find()
    },
    onUpdate(user) {
      router.push(
        {
          name: 'UserUpdate',
          params: {
            id: user.id,
            company: user.companyId,
            service: user.serviceId,
          },
        },
      )
    },
    remove(user, index) {
      const _this = this
      _this
        .$confirm(i18n.t('irreversibleProcess'), 'Warning', {
          confirmButtonText: i18n.t('yes'),
          cancelButtonText: i18n.t('no'),
          type: 'warning',
        })
        .then(() => {
          _this.loading = true
          _this.$http.delete(`users/${user.id}/${user.companyId}/${user.serviceId}`).then(
            response => {
              _this.$message({ type: 'success', message: i18n.t('processSuccess') })
              _this.loading = false
              _this.find()
            },
            response => {
              _this.loading = false
            },
          )
        })
    },
  },
}
</script>
